import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="bg-gray-100 dark:bg-gray-800">
      <div className="max-w-7xl px-6 py-6 mx-auto lg:px-8">
        <div className="md:flex">
          <div className="w-full -mx-4 sm:-mx-6 md:-mx-8 md:w-1/3">
            <div className="px-4 sm:px-6 md:px-8">
              <Link className="flex items-center gap-1" to="/">
                {/*
                <StaticImage
                  src="../../static/media/logo-tc-freinsheim.png"
                  alt="Logo Club"
                  className="mr-2"
                  layout="fixed"
                  width={40}
                  height={40}
                  backgroundColor="transparent"
                  placeholder="blurred"
                />
                */}
                <div className="text-xl font-bold tracking-wide">
                  <span className="text-gray-800 dark:text-gray-400">TC Freinsheim</span>
                </div>
              </Link>

              <p className="max-w-md mt-2 text-gray-600 dark:text-gray-400">
                Adresse Anlage
                <br />
                Burgstraße 23
                <br />
                67251 Freinsheim
              </p>
            </div>
          </div>

          <div className="mt-6 lg:mt-0 md:flex-1">
            <div className="grid grid-cols-2 md:justify-items-center gap-6 sm:grid-cols-2 md:grid-cols-2">
              <div>
                <h3 className="text-gray-700 uppercase dark:text-gray-300">
                  Kontakt
                </h3>
                <span className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">
                  <a href="tel:+4915732312769">Telefon: 0157-32312769</a>
                </span>
                <span className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline truncate">
                  <a href="mailto:info.tc.freinsheim@googlemail.com">
                    info.tc.freinsheim@gmail.com
                  </a>
                </span>
              </div>

              <div className="text-right md:text-left">
                <h3 className="text-gray-700 uppercase dark:text-gray-300">
                  Club
                </h3>
                <Link
                  to="/club/mitgliedschaft/"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  Mitgliedschaft
                </Link>
                <Link
                  to="/club/team/"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  Kontakt
                </Link>
                <Link
                  to="/club/"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  Vereinsinfo
                </Link>
              </div>
            </div>
          </div>
        </div>

        <hr className="h-px my-6 bg-gray-300 border-none dark:bg-gray-700" />

        <div className="flex flex-wrap justify-between items-baseline my-6">
          <div className="mr-4 text-center min-w-full sm:min-w-0">
            <Link
              to="/impressum/"
              className="text-sm text-gray-600 dark:text-gray-400 hover:underline"
            >
              Impressum
            </Link>

            <Link
              to="/datenschutz/"
              className="ml-4 sm:ml-6 text-sm text-gray-600 dark:text-gray-400 hover:underline"
            >
              Datenschutz
            </Link>
          </div>
          <div className="text-center min-w-full sm:min-w-0 mt-2 sm:mt-0 sm:pr-2">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              © Tennisclub Freinsheim e.V. { new Date().getFullYear() }
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
